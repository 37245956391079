// source: pbtypes/vehicle/ambassador/ambassador.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_ambassador_flight_state_pb = require('../../../pbtypes/gen/ambassador/flight_state_pb.js');
goog.object.extend(proto, pbtypes_gen_ambassador_flight_state_pb);
var pbtypes_gen_flight_deck_task_kind_pb = require('../../../pbtypes/gen/flight_deck/task_kind_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_deck_task_kind_pb);
var pbtypes_gen_planner_hand_takeoff_state_pb = require('../../../pbtypes/gen/planner/hand_takeoff_state_pb.js');
goog.object.extend(proto, pbtypes_gen_planner_hand_takeoff_state_pb);
var pbtypes_gen_skills_lost_connection_destination_pb = require('../../../pbtypes/gen/skills/lost_connection_destination_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_lost_connection_destination_pb);
var pbtypes_gen_user_prompts_flight_status_message_pb = require('../../../pbtypes/gen/user_prompts/flight_status_message_pb.js');
goog.object.extend(proto, pbtypes_gen_user_prompts_flight_status_message_pb);
goog.exportSymbol('proto.ambassador.FlightStateAndMessage', null, global);
goog.exportSymbol('proto.ambassador.GroundHeight', null, global);
goog.exportSymbol('proto.ambassador.HeightAboveTakeoff', null, global);
goog.exportSymbol('proto.ambassador.MinimalGps', null, global);
goog.exportSymbol('proto.ambassador.NavSourceInfo', null, global);
goog.exportSymbol('proto.ambassador.NavSourceInfo.NavHealth', null, global);
goog.exportSymbol('proto.ambassador.NavSourceInfo.NavSource', null, global);
goog.exportSymbol('proto.ambassador.VehicleStatus', null, global);
goog.exportSymbol('proto.ambassador.Wind', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.VehicleStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.VehicleStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.VehicleStatus.displayName = 'proto.ambassador.VehicleStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.GroundHeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.GroundHeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.GroundHeight.displayName = 'proto.ambassador.GroundHeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.MinimalGps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.MinimalGps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.MinimalGps.displayName = 'proto.ambassador.MinimalGps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.FlightStateAndMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.FlightStateAndMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.FlightStateAndMessage.displayName = 'proto.ambassador.FlightStateAndMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.HeightAboveTakeoff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.HeightAboveTakeoff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.HeightAboveTakeoff.displayName = 'proto.ambassador.HeightAboveTakeoff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.NavSourceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.NavSourceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.NavSourceInfo.displayName = 'proto.ambassador.NavSourceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.Wind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.Wind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.Wind.displayName = 'proto.ambassador.Wind';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.VehicleStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.VehicleStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.VehicleStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.VehicleStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
activeTaskKind: jspb.Message.getFieldWithDefault(msg, 2, 0),
gpsConverged: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
handTakeoffState: jspb.Message.getFieldWithDefault(msg, 4, 0),
groundHeight: (f = msg.getGroundHeight()) && proto.ambassador.GroundHeight.toObject(includeInstance, f),
globalYawNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
flightState: jspb.Message.getFieldWithDefault(msg, 7, 0),
rssi: jspb.Message.getFieldWithDefault(msg, 8, 0),
vehicleGps: (f = msg.getVehicleGps()) && proto.ambassador.MinimalGps.toObject(includeInstance, f),
vehicleGpsHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
vehicleGpsManual: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
lostConnectionDestination: (f = msg.getLostConnectionDestination()) && pbtypes_gen_skills_lost_connection_destination_pb.LostConnectionDestination.toObject(includeInstance, f),
wifiSignalQuality: jspb.Message.getFieldWithDefault(msg, 12, 0),
pilotCloudUserId: jspb.Message.getFieldWithDefault(msg, 13, ""),
heightAboveTakeoff: (f = msg.getHeightAboveTakeoff()) && proto.ambassador.HeightAboveTakeoff.toObject(includeInstance, f),
obstacleSafety: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
navSourceInfo: (f = msg.getNavSourceInfo()) && proto.ambassador.NavSourceInfo.toObject(includeInstance, f),
nightsenseToggleAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
magHeadingValid: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
returnHeadingNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
wind: (f = msg.getWind()) && proto.ambassador.Wind.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.VehicleStatus}
 */
proto.ambassador.VehicleStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.VehicleStatus;
  return proto.ambassador.VehicleStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.VehicleStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.VehicleStatus}
 */
proto.ambassador.VehicleStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.flight_deck.TaskKind.Enum} */ (reader.readEnum());
      msg.setActiveTaskKind(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGpsConverged(value);
      break;
    case 4:
      var value = /** @type {!proto.planner.HandTakeoffState.Enum} */ (reader.readEnum());
      msg.setHandTakeoffState(value);
      break;
    case 5:
      var value = new proto.ambassador.GroundHeight;
      reader.readMessage(value,proto.ambassador.GroundHeight.deserializeBinaryFromReader);
      msg.setGroundHeight(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGlobalYawNav(value);
      break;
    case 7:
      var value = /** @type {!proto.ambassador.FlightState.Enum} */ (reader.readEnum());
      msg.setFlightState(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRssi(value);
      break;
    case 9:
      var value = new proto.ambassador.MinimalGps;
      reader.readMessage(value,proto.ambassador.MinimalGps.deserializeBinaryFromReader);
      msg.setVehicleGps(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVehicleGpsHeading(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleGpsManual(value);
      break;
    case 10:
      var value = new pbtypes_gen_skills_lost_connection_destination_pb.LostConnectionDestination;
      reader.readMessage(value,pbtypes_gen_skills_lost_connection_destination_pb.LostConnectionDestination.deserializeBinaryFromReader);
      msg.setLostConnectionDestination(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWifiSignalQuality(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPilotCloudUserId(value);
      break;
    case 14:
      var value = new proto.ambassador.HeightAboveTakeoff;
      reader.readMessage(value,proto.ambassador.HeightAboveTakeoff.deserializeBinaryFromReader);
      msg.setHeightAboveTakeoff(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setObstacleSafety(value);
      break;
    case 17:
      var value = new proto.ambassador.NavSourceInfo;
      reader.readMessage(value,proto.ambassador.NavSourceInfo.deserializeBinaryFromReader);
      msg.setNavSourceInfo(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNightsenseToggleAllowed(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMagHeadingValid(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setReturnHeadingNav(value);
      break;
    case 22:
      var value = new proto.ambassador.Wind;
      reader.readMessage(value,proto.ambassador.Wind.deserializeBinaryFromReader);
      msg.setWind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.VehicleStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.VehicleStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.VehicleStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.VehicleStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActiveTaskKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGpsConverged();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHandTakeoffState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGroundHeight();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ambassador.GroundHeight.serializeBinaryToWriter
    );
  }
  f = message.getGlobalYawNav();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getFlightState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRssi();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getVehicleGps();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.ambassador.MinimalGps.serializeBinaryToWriter
    );
  }
  f = message.getVehicleGpsHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getVehicleGpsManual();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getLostConnectionDestination();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_gen_skills_lost_connection_destination_pb.LostConnectionDestination.serializeBinaryToWriter
    );
  }
  f = message.getWifiSignalQuality();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPilotCloudUserId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHeightAboveTakeoff();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.ambassador.HeightAboveTakeoff.serializeBinaryToWriter
    );
  }
  f = message.getObstacleSafety();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getNavSourceInfo();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.ambassador.NavSourceInfo.serializeBinaryToWriter
    );
  }
  f = message.getNightsenseToggleAllowed();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getMagHeadingValid();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getReturnHeadingNav();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getWind();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.ambassador.Wind.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional flight_deck.TaskKind.Enum active_task_kind = 2;
 * @return {!proto.flight_deck.TaskKind.Enum}
 */
proto.ambassador.VehicleStatus.prototype.getActiveTaskKind = function() {
  return /** @type {!proto.flight_deck.TaskKind.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flight_deck.TaskKind.Enum} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setActiveTaskKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool gps_converged = 3;
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.getGpsConverged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setGpsConverged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional planner.HandTakeoffState.Enum hand_takeoff_state = 4;
 * @return {!proto.planner.HandTakeoffState.Enum}
 */
proto.ambassador.VehicleStatus.prototype.getHandTakeoffState = function() {
  return /** @type {!proto.planner.HandTakeoffState.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.planner.HandTakeoffState.Enum} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setHandTakeoffState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional GroundHeight ground_height = 5;
 * @return {?proto.ambassador.GroundHeight}
 */
proto.ambassador.VehicleStatus.prototype.getGroundHeight = function() {
  return /** @type{?proto.ambassador.GroundHeight} */ (
    jspb.Message.getWrapperField(this, proto.ambassador.GroundHeight, 5));
};


/**
 * @param {?proto.ambassador.GroundHeight|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setGroundHeight = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearGroundHeight = function() {
  return this.setGroundHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasGroundHeight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float global_yaw_nav = 11;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getGlobalYawNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setGlobalYawNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional FlightState.Enum flight_state = 7;
 * @return {!proto.ambassador.FlightState.Enum}
 */
proto.ambassador.VehicleStatus.prototype.getFlightState = function() {
  return /** @type {!proto.ambassador.FlightState.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ambassador.FlightState.Enum} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setFlightState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 rssi = 8;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setRssi = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional MinimalGps vehicle_gps = 9;
 * @return {?proto.ambassador.MinimalGps}
 */
proto.ambassador.VehicleStatus.prototype.getVehicleGps = function() {
  return /** @type{?proto.ambassador.MinimalGps} */ (
    jspb.Message.getWrapperField(this, proto.ambassador.MinimalGps, 9));
};


/**
 * @param {?proto.ambassador.MinimalGps|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setVehicleGps = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearVehicleGps = function() {
  return this.setVehicleGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasVehicleGps = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional float vehicle_gps_heading = 16;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getVehicleGpsHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setVehicleGpsHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional bool vehicle_gps_manual = 21;
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.getVehicleGpsManual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setVehicleGpsManual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional skills.LostConnectionDestination lost_connection_destination = 10;
 * @return {?proto.skills.LostConnectionDestination}
 */
proto.ambassador.VehicleStatus.prototype.getLostConnectionDestination = function() {
  return /** @type{?proto.skills.LostConnectionDestination} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_lost_connection_destination_pb.LostConnectionDestination, 10));
};


/**
 * @param {?proto.skills.LostConnectionDestination|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setLostConnectionDestination = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearLostConnectionDestination = function() {
  return this.setLostConnectionDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasLostConnectionDestination = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 wifi_signal_quality = 12;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getWifiSignalQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setWifiSignalQuality = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string pilot_cloud_user_id = 13;
 * @return {string}
 */
proto.ambassador.VehicleStatus.prototype.getPilotCloudUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setPilotCloudUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional HeightAboveTakeoff height_above_takeoff = 14;
 * @return {?proto.ambassador.HeightAboveTakeoff}
 */
proto.ambassador.VehicleStatus.prototype.getHeightAboveTakeoff = function() {
  return /** @type{?proto.ambassador.HeightAboveTakeoff} */ (
    jspb.Message.getWrapperField(this, proto.ambassador.HeightAboveTakeoff, 14));
};


/**
 * @param {?proto.ambassador.HeightAboveTakeoff|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setHeightAboveTakeoff = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearHeightAboveTakeoff = function() {
  return this.setHeightAboveTakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasHeightAboveTakeoff = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional float obstacle_safety = 15;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getObstacleSafety = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setObstacleSafety = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional NavSourceInfo nav_source_info = 17;
 * @return {?proto.ambassador.NavSourceInfo}
 */
proto.ambassador.VehicleStatus.prototype.getNavSourceInfo = function() {
  return /** @type{?proto.ambassador.NavSourceInfo} */ (
    jspb.Message.getWrapperField(this, proto.ambassador.NavSourceInfo, 17));
};


/**
 * @param {?proto.ambassador.NavSourceInfo|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setNavSourceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearNavSourceInfo = function() {
  return this.setNavSourceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasNavSourceInfo = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool nightsense_toggle_allowed = 18;
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.getNightsenseToggleAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setNightsenseToggleAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool mag_heading_valid = 19;
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.getMagHeadingValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setMagHeadingValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional float return_heading_nav = 20;
 * @return {number}
 */
proto.ambassador.VehicleStatus.prototype.getReturnHeadingNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.setReturnHeadingNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional Wind wind = 22;
 * @return {?proto.ambassador.Wind}
 */
proto.ambassador.VehicleStatus.prototype.getWind = function() {
  return /** @type{?proto.ambassador.Wind} */ (
    jspb.Message.getWrapperField(this, proto.ambassador.Wind, 22));
};


/**
 * @param {?proto.ambassador.Wind|undefined} value
 * @return {!proto.ambassador.VehicleStatus} returns this
*/
proto.ambassador.VehicleStatus.prototype.setWind = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ambassador.VehicleStatus} returns this
 */
proto.ambassador.VehicleStatus.prototype.clearWind = function() {
  return this.setWind(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ambassador.VehicleStatus.prototype.hasWind = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.GroundHeight.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.GroundHeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.GroundHeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.GroundHeight.toObject = function(includeInstance, msg) {
  var f, obj = {
height: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
age: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.GroundHeight}
 */
proto.ambassador.GroundHeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.GroundHeight;
  return proto.ambassador.GroundHeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.GroundHeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.GroundHeight}
 */
proto.ambassador.GroundHeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.GroundHeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.GroundHeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.GroundHeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.GroundHeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAge();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float height = 3;
 * @return {number}
 */
proto.ambassador.GroundHeight.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.GroundHeight} returns this
 */
proto.ambassador.GroundHeight.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float age = 4;
 * @return {number}
 */
proto.ambassador.GroundHeight.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.GroundHeight} returns this
 */
proto.ambassador.GroundHeight.prototype.setAge = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.MinimalGps.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.MinimalGps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.MinimalGps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.MinimalGps.toObject = function(includeInstance, msg) {
  var f, obj = {
latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
groundSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
numSatellitesUsed: jspb.Message.getFieldWithDefault(msg, 5, 0),
fixType: jspb.Message.getFieldWithDefault(msg, 6, 0),
horizontalAccuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
gpsUclock: jspb.Message.getFieldWithDefault(msg, 8, 0),
source: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.MinimalGps}
 */
proto.ambassador.MinimalGps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.MinimalGps;
  return proto.ambassador.MinimalGps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.MinimalGps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.MinimalGps}
 */
proto.ambassador.MinimalGps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundSpeed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSatellitesUsed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFixType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHorizontalAccuracy(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGpsUclock(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.MinimalGps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.MinimalGps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.MinimalGps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.MinimalGps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getGroundSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getNumSatellitesUsed();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFixType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHorizontalAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getGpsUclock();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSource();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float altitude = 3;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float ground_speed = 4;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getGroundSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setGroundSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 num_satellites_used = 5;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getNumSatellitesUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setNumSatellitesUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 fix_type = 6;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getFixType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setFixType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float horizontal_accuracy = 7;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getHorizontalAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setHorizontalAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 gps_uclock = 8;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getGpsUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setGpsUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 source = 9;
 * @return {number}
 */
proto.ambassador.MinimalGps.prototype.getSource = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.MinimalGps} returns this
 */
proto.ambassador.MinimalGps.prototype.setSource = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.FlightStateAndMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.FlightStateAndMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.FlightStateAndMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.FlightStateAndMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
flightState: jspb.Message.getFieldWithDefault(msg, 2, 0),
flightStatusMessage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.FlightStateAndMessage}
 */
proto.ambassador.FlightStateAndMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.FlightStateAndMessage;
  return proto.ambassador.FlightStateAndMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.FlightStateAndMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.FlightStateAndMessage}
 */
proto.ambassador.FlightStateAndMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.ambassador.FlightState.Enum} */ (reader.readEnum());
      msg.setFlightState(value);
      break;
    case 3:
      var value = /** @type {!proto.user_prompts.FlightStatusMessage.Enum} */ (reader.readEnum());
      msg.setFlightStatusMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.FlightStateAndMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.FlightStateAndMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.FlightStateAndMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.FlightStateAndMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFlightState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFlightStatusMessage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.ambassador.FlightStateAndMessage.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.FlightStateAndMessage} returns this
 */
proto.ambassador.FlightStateAndMessage.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FlightState.Enum flight_state = 2;
 * @return {!proto.ambassador.FlightState.Enum}
 */
proto.ambassador.FlightStateAndMessage.prototype.getFlightState = function() {
  return /** @type {!proto.ambassador.FlightState.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ambassador.FlightState.Enum} value
 * @return {!proto.ambassador.FlightStateAndMessage} returns this
 */
proto.ambassador.FlightStateAndMessage.prototype.setFlightState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional user_prompts.FlightStatusMessage.Enum flight_status_message = 3;
 * @return {!proto.user_prompts.FlightStatusMessage.Enum}
 */
proto.ambassador.FlightStateAndMessage.prototype.getFlightStatusMessage = function() {
  return /** @type {!proto.user_prompts.FlightStatusMessage.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.user_prompts.FlightStatusMessage.Enum} value
 * @return {!proto.ambassador.FlightStateAndMessage} returns this
 */
proto.ambassador.FlightStateAndMessage.prototype.setFlightStatusMessage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.HeightAboveTakeoff.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.HeightAboveTakeoff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.HeightAboveTakeoff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.HeightAboveTakeoff.toObject = function(includeInstance, msg) {
  var f, obj = {
height: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.HeightAboveTakeoff}
 */
proto.ambassador.HeightAboveTakeoff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.HeightAboveTakeoff;
  return proto.ambassador.HeightAboveTakeoff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.HeightAboveTakeoff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.HeightAboveTakeoff}
 */
proto.ambassador.HeightAboveTakeoff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.HeightAboveTakeoff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.HeightAboveTakeoff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.HeightAboveTakeoff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.HeightAboveTakeoff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float height = 1;
 * @return {number}
 */
proto.ambassador.HeightAboveTakeoff.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.HeightAboveTakeoff} returns this
 */
proto.ambassador.HeightAboveTakeoff.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.NavSourceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.NavSourceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.NavSourceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.NavSourceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
activeNavSource: jspb.Message.getFieldWithDefault(msg, 1, 0),
vioHealth: jspb.Message.getFieldWithDefault(msg, 2, 0),
gpsHealth: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.NavSourceInfo}
 */
proto.ambassador.NavSourceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.NavSourceInfo;
  return proto.ambassador.NavSourceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.NavSourceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.NavSourceInfo}
 */
proto.ambassador.NavSourceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ambassador.NavSourceInfo.NavSource} */ (reader.readEnum());
      msg.setActiveNavSource(value);
      break;
    case 2:
      var value = /** @type {!proto.ambassador.NavSourceInfo.NavHealth} */ (reader.readEnum());
      msg.setVioHealth(value);
      break;
    case 3:
      var value = /** @type {!proto.ambassador.NavSourceInfo.NavHealth} */ (reader.readEnum());
      msg.setGpsHealth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.NavSourceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.NavSourceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.NavSourceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.NavSourceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveNavSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVioHealth();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGpsHealth();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ambassador.NavSourceInfo.NavSource = {
  INVALID: 0,
  ATTITUDE: 1,
  VIO: 2,
  GPS: 3
};

/**
 * @enum {number}
 */
proto.ambassador.NavSourceInfo.NavHealth = {
  DISABLED: 0,
  UNRELIABLE: 1,
  DEGRADED: 2,
  AWAITING_HEADING: 3,
  HEALTHY: 4,
  VERY_DEGRADED: 5
};

/**
 * optional NavSource active_nav_source = 1;
 * @return {!proto.ambassador.NavSourceInfo.NavSource}
 */
proto.ambassador.NavSourceInfo.prototype.getActiveNavSource = function() {
  return /** @type {!proto.ambassador.NavSourceInfo.NavSource} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ambassador.NavSourceInfo.NavSource} value
 * @return {!proto.ambassador.NavSourceInfo} returns this
 */
proto.ambassador.NavSourceInfo.prototype.setActiveNavSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NavHealth vio_health = 2;
 * @return {!proto.ambassador.NavSourceInfo.NavHealth}
 */
proto.ambassador.NavSourceInfo.prototype.getVioHealth = function() {
  return /** @type {!proto.ambassador.NavSourceInfo.NavHealth} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ambassador.NavSourceInfo.NavHealth} value
 * @return {!proto.ambassador.NavSourceInfo} returns this
 */
proto.ambassador.NavSourceInfo.prototype.setVioHealth = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional NavHealth gps_health = 3;
 * @return {!proto.ambassador.NavSourceInfo.NavHealth}
 */
proto.ambassador.NavSourceInfo.prototype.getGpsHealth = function() {
  return /** @type {!proto.ambassador.NavSourceInfo.NavHealth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ambassador.NavSourceInfo.NavHealth} value
 * @return {!proto.ambassador.NavSourceInfo} returns this
 */
proto.ambassador.NavSourceInfo.prototype.setGpsHealth = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.Wind.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.Wind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.Wind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.Wind.toObject = function(includeInstance, msg) {
  var f, obj = {
heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.Wind}
 */
proto.ambassador.Wind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.Wind;
  return proto.ambassador.Wind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.Wind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.Wind}
 */
proto.ambassador.Wind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeading(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.Wind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.Wind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.Wind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.Wind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float heading = 1;
 * @return {number}
 */
proto.ambassador.Wind.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.Wind} returns this
 */
proto.ambassador.Wind.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float speed = 2;
 * @return {number}
 */
proto.ambassador.Wind.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ambassador.Wind} returns this
 */
proto.ambassador.Wind.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


goog.object.extend(exports, proto.ambassador);
